$blueColor: #1749b9;
$greenColor: #13bf11;
$redColor: #fa0000;
$blackColor: #000;
$whiteColor: #fff;
$lightGrayColor: #535353;
$greyColor:#eee;
$tabBlueColor: #303c8f;
$statusMedium: #f707f7;
$statusHigh: #ff0001;
$statusLow: #09b476;
$indicatorGreen: #23a10f;
$indicatorBlue: $blueColor;
$indicatorGrey: $lightGrayColor;
$indicatorRed: #ef4047;
$indicatorYellow: #ffc55f;
