
.login-page-bg {
	height: 100vh;
	position: relative;
	overflow: auto;
	background: #fff;
}
.login-box {
	width: 100%;
	max-width: 1000px;
	margin: auto;
	box-shadow: 0 0 13px 0px #eeeeee94;
}
.inner-content-update {
	font-size: 1.4rem;
	overflow: auto;
}
.login-wrap {
	.form-control {
		padding: 9px;
		border-radius: 0;
		font-size: 1.6rem;
	}
}
.login-waves {
	width: 100%;
	margin-bottom: 0px;
	min-height: 109px;
	max-height: 109px;
	.parallax {
		& > use {
			animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
			&:nth-child(1) {
				animation-delay: -2s;
				animation-duration: 7s;
			}
			&:nth-child(2) {
				animation-delay: -3s;
				animation-duration: 10s;
			}
			&:nth-child(3) {
				animation-delay: -4s;
				animation-duration: 13s;
			}
			&:nth-child(4) {
				animation-delay: -5s;
				animation-duration: 20s;
			}
			@keyframes move-forever {
				0% {
					transform: translate3d(-90px, 0, 0);
				}
				100% {
					transform: translate3d(85px, 0, 0);
				}
			}
		}
	}
}
.login-footer {
	background: white;
	margin-bottom: 0;
	padding: 13px 0;
	a {
		color: $blackColor;
	}
}
