.custom-checkbox {
	input {
		padding: 0;
		height: initial;
		width: initial;
		margin-bottom: 0;
		display: none;
		cursor: pointer;
		&:checked {
			& + label {
				&:after {
					content: "";
					display: block;
					position: absolute;
					top: 3px;
					left: 7px;
					width: 5px;
					height: 10px;
					border: solid $blueColor;
					border-width: 0 2px 2px 0;
					transform: rotate(45deg);
				}
			}
		}
		& + label {
			line-height: 0;
		}
	}
	label {
		position: relative;
		cursor: pointer;
		&:before {
			content: "";
			-webkit-appearance: none;
			appearance: none;
			background-color: transparent;
			border: 1px solid $lightGrayColor;
			padding: 8px;
			display: inline-block;
			position: relative;
			vertical-align: middle;
			border-radius: 2px;
			cursor: pointer;
			margin-right: 5px;
		}
	}
}

.circle-indicator {
	display: inline-block;
	height: 8px;
	min-width: 8px;
	border-radius: 50%;
	background-color: $lightGrayColor;
	margin-right: 8px;
	margin-top: 3px;
}
.green-indictor {
	background-color: $indicatorGreen;
}
.blue-indictor {
	background-color: $indicatorBlue;
}
.grey-indictor {
	background-color: $indicatorGrey;
}
.red-indictor {
	background-color: $indicatorRed;
}
.yellow-indictor {
	background-color: $indicatorYellow;
}
.header-body-content {
	margin-left: 210px;
	transition: 0.3s;
	height: 100%;
	display: flex;
	flex-direction: column;
}

#template-default-page {
	&.active {
		.header-body-content {
			margin-left: 70px;
		}
	}
}

.body-content {
	height: 100%;
	overflow: auto;
}

.btn {
	&:hover {
		opacity: 0.9;
	}
}

.cursor-normal {
	cursor: auto;
}

.before-none::before,
.before-none::after {
	content: none;
}


.prority-status {
	width: 7px;
	height: 7px;
	display: inline-block;
	background: #eee;
	border-radius: 50%;
	margin-right: 10px;
	&.medium {
		background-color: $statusMedium;
	}
	&.high {
		background-color: $statusHigh;
	}
	&.low {
		background-color: $statusLow;
	}
}


// Custom tabs= ==========================================
.custom-tabs {
	.nav {
		gap: 5px;
	}
	.nav-link {
		font-size: 1.4rem;
		white-space: nowrap;
		color: $blackColor;
		font-weight: 500;
		background-color: #e7e6e6;
		border-radius: 0;
		padding: 5px 30px;
		width: 100%;
		text-transform: capitalize;
		&.active {
			border-bottom: 1px solid $lightGrayColor;
			background: $tabBlueColor;
			color: $whiteColor;
		}
	}
}

// =================== forms fields =====================
.form-group{
	label{
		font-weight: 500;
		margin-bottom: 0.5rem;
	}
}

.main-search-box {
	min-width: 200px;
}

.form-check-input {
	cursor: pointer;
	&:checked {
		background-color: $blueColor;
		border-color: $blueColor;
	}
}



// =================== dropdown =====================
.dropdown-toggle {
	&:after {
		content: "\f107";
		font-family: "Font Awesome 6 Pro";
		border: 0;
		line-height: 1;
		margin-left: 10px;
		vertical-align: baseline;
	}
}

.dropdown-icon-none {
	&:after {
		content: none !important;
	}
}


.custom-dessign-dropdown {
	.dropdown-toggle {
		&:after {
			content: "\f107";
			font-family: "Font Awesome 6 Pro";
			border: 0;
			line-height: 1;
			vertical-align: baseline;
		}
	}
	.dropdown-btn {
		font-size: 1.5rem;
		width: 200px;
		white-space: nowrap;
		text-align: left;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.dropdown-menu {
		width: 100%;
		max-width: 245px;
		border-color: #cfd7df;
		position: fixed !important;
		li {
			a {
				font-size: 1.4rem;
				color: #292728;
			}
		}
	}
	.dropdownRight {
		position: fixed !important;
		right: 0 !important;
		transform: none !important;
		left: auto !important;
		height: 100%;
		border-radius: 0;
	}
	.filterBox {
		padding-top: 10px;
		padding-right: 0;
		height: 100%;
		overflow: auto;
		display: flex;
		flex-direction: column;
		.title {
			padding-left: 30px;
		}
		.btn-group {
			padding: 0 30px;
		}
	}
}

.progress-departed-arrived {
	list-style-type: none;
	display: block;
	min-width: 406px;
	padding: 0;
	overflow: hidden;
	& > li {
		padding: 0;
		background-color: #fff;
		a {
			font-size: 1.4rem;
			color: $blackColor;
			background-color: #fff;
			max-width: 135px;
			width: 100%;
			padding-left: 20px;
			padding-right: 20px;
			border-radius: 0;
			border: 0;
			width: 132px;
			position: relative;
		}
		.close {
			position: absolute;
			right: 1px;
			top: 0;
			bottom: 0;
			font-size: 1.3rem;
			border: 0;
			background-color: transparent;
			cursor: pointer;
			line-height: 0;
			opacity: 0;
			padding: 0 10px;
			&:hover {
				background-color: #eee;
			}
		}
		.dragger{
			position: absolute;
			z-index: 1;
			height: 100%;
			display: flex;
			align-items: center;
			padding: 0 8px;
			cursor: pointer;
			&:hover{
				background-color: #eee;
			}
		}
	}
}


.progress-departed-arrived > li a:hover,
.progress-departed-arrived > li.active a {
	background-color: #f0f0f9;
}
.progress-departed-arrived > li.active .close,
.progress-departed-arrived > li.active a::before,
.progress-departed-arrived > li:hover .close,
.progress-departed-arrived > li:hover a::before {
	opacity: 1;
}


.departed-dropdown-wrap {
	min-width: 200px;
}

.dropdown-with-search {
	position: relative;
	&::before {
		content: "";
		position: absolute;
		top: -6px;
		left: 24px;
		background: #fff;
		width: 10px;
		height: 10px;
		border-left: 1px solid #cfd7df;
		border-top: 1px solid #cfd7df;
		transform: rotate(45deg);
	}
	ul {
		overflow: auto;
	}
}


.dropdownRight {
	padding: 0 !important;
	.filterBox {
		background-color: #fff;
	}
}
.departed-dropdown {
	width: 200px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.blurDropdownBg {
	&::before {
		content: "";
		position: fixed;
		height: 100%;
		width: 100%;
		background: $blackColor;
		left: 0;
		top: 0;
		pointer-events: none;
		z-index: 2;
		transition: 0.2s;
		opacity: 0;
	}
	&.show {
		&::before {
			opacity: 1;
		}
	}
}
.dropdown-large {
	min-width: 540px;
}
.dropdown-x-large {
	min-width: 790px;
}
.filter-form-wrap {
	padding: 10px 20px;
	overflow: auto;
}
.dropdown-item.active,
.dropdown-item:active {
	color: inherit;
	background-color: inherit;
}
.dropdown-item {
	padding: 0.35rem 1rem;
	letter-spacing: 0.3px;
}
.saveViewAs {
	margin-top: -64px !important;
	margin-left: -1px !important;
}


.dropdown-small {
	width: 100%;
	max-width: 200px !important;
}
.dropdown-large {
	width: 100%;
	max-width: 590px !important;
}
.dropdown-x-large {
	width: 100%;
	max-width: 800px !important;
}

// =================== offcanvas =====================
.modal-open-right {
	.modal-dialog {
		position: fixed;
		right: 0;
		top: 0;
		height: 100%;
		bottom: 0;
		max-width: 850px;
		margin: 0;
		.modal-content {
			height: 100%;
			border-radius: 0;
		}
		.modal-body {
			overflow: auto;
		}
	}
	&.login-page-right-modal {
		.modal-dialog {
			max-width: 470px;
		}
	}
}
.offcanvas .close-btn,
.dropdownRight .dropdown-close-btn {
	position: absolute;
	background: #fff;
	width: 40px;
	height: 40px;
	display: flex;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
	top: 0;
}
.offcanvas {
	.btn-close {
		opacity: 1;
		position: absolute;
		background-color: #fff;
		width: 44px;
		height: 40px;
		display: flex;
		cursor: pointer;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		top: 5px;
		color: #c1c1c1;
		border-radius: 0px;
		box-shadow: none;
		padding: 0px;
	}
	&.xxl {
		max-width: 80% !important;
	}
	&.xl {
		max-width: 50% !important;
	}
	&.lg {
		max-width: 35% !important;
	}
	&.sm {
		max-width: 25% !important;
	}
}
.offcanvas-end {
	.btn-close {
		margin-right: -5px;
		right: 101%;
		border-right: 1px solid;
	}
}
.offcanvas-start {
	.btn-close {
		right: -40px;
		border-left: 1px solid;
	}
}
.filterBox {
	display: flex;
	height: 100%;
	flex-direction: column;
}

// Swal==================
.swal2-html-container{
    font-size: 14px;
	margin-top: 5px;
}