.template-default {
	flex: 1;
	overflow: hidden;
	aside#asidebar {
		ul {
			li {
				&:hover {
					a {
						span {
							opacity: 1;
							visibility: visible;
						}
					}
				}
				span {
					opacity: 1;
					position: static;
					background-color: transparent;
					margin: 0;
					padding: 0;
					margin-left: -10px;
					height: auto;
					border-radius: unset;
					font-weight: inherit;
				}
			}
		}
	}
	&.night-mode {
		aside#asidebar {
			ul {
				li {
					a {
						color: #fff;
					}
				}
			}
		}
	}
}

aside#asidebar {
	width: 210px;
	position: fixed;
	height: 1px;
	background-color: #fff;
	z-index: 8;
	min-height: 100%;
	transition: 0.3s;
	box-shadow: 1px 1px 4px 0px #ccc;
	display: flex;
	flex-direction: column;
}


.nav-sidebar {
	width: 100%;
	white-space: nowrap;
	flex-grow: 1;
	border-top: 1px solid #dee2e6;
	overflow: auto;
	ul {
		li {
			a {
				display: block;
				padding: 8px 0;
				text-decoration: none;
				color: $blackColor;
				margin-bottom: 5px;
				position: relative;
				&::before {
					content: "";
					height: 100%;
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					background-color: #ce2027;
					width: 2px;
					opacity: 0;
				}
			}
		}
	}
}
.nav-sidebar ul li a.active,
.nav-sidebar ul li:hover > a {
	background-color: #f9f8ff;
	color: $blueColor;
	font-weight: var(--fw600);
}
.nav-sidebar ul li a.active::before,
.nav-sidebar ul li:hover > a::before {
	opacity: 1;
}
.nav-tooltip {
	pointer-events: none;
	.tooltip-inner {
		font-size: 10px;
		background: #aea4a4;
		text-transform: capitalize;
	}
	.tooltip-arrow {
		&::before {
			border-right-color: #aea4a4;
		}
	}
}
.nav-sidebar ul li a.active i,
.nav-sidebar ul li:hover > a i {
	color: #ef4047;
}
.nav-sidebar ul li a i,
.change-theme i {
	font-size: 1.8rem;
	width: 62px;
	text-align: center;
	vertical-align: text-bottom;
}

.template-default.night-mode aside#asidebar ul li:hover a,
.template-default.night-mode aside#asidebar ul li a.active,
.night-mode .hamburger {
	background: #212529;
}
.change-theme {
	display: block;
	padding: 8px 0;
	text-decoration: none;
	color: $blackColor;
	font-size: 16px;
	cursor: pointer;
	width: 100%;
	text-align: left;
}
.night-mode {
	.change-theme {
		color: #fff;
	}
}
.menu-closed {
	.change-theme {
		display: none;
	}
}

@media (min-width: 1200px) {
	.template-default {
		&.menu-closed {
			.hamburger {
				transform: rotate(180deg);
			}
			#asidebar {
				width: 62px;
			}
			.logo {
				width: auto;
			}
			aside#asidebar {
				ul {
					li {
						span {
							opacity: 0;
							position: absolute;
							background: #6c6c6c;
							padding: 5px 20px;
							margin: auto;
							left: 120%;
							border-radius: 5px;
							top: 0;
							font-size: 1.5rem;
							bottom: 0;
							height: fit-content;
							text-transform: capitalize;
							visibility: hidden;
							font-weight: 500;
							margin-left: 0px;
							color: #fff !important;
							&::before {
								content: "";
								border-width: 7px;
								border-style: solid;
								border-color: transparent #6c6c6c transparent transparent;
								position: absolute;
								left: -14px;
								top: 7px;
							}
						}
						svg {
							font-size: 1.8rem;
						}
					}
				}
			}
			.header-body-content {
				margin-left: 70px;
			}
			.sm-logo {
				display: block;
			}
			.lg-logo {
				display: none;
			}
		}
		.sm-logo {
			display: none;
		}
		.lg-logo {
			display: block;
		}
	}
}

.night-mode {
	#asidebar {
		background-color: #30353a;
	}
	&.template-default {
		&.menu-closed {
			aside#asidebar {
				ul {
					li {
						span {
							color: #fff;
							transition: none;
						}
					}
				}
			}
		}
	}
}
.night-mode .nav-sidebar ul li a i,
.night-mode .hamburger {
	color: #fff;
}