@import 'variables';

.shipment-card-wrapper {
	background-color: #f2f2f2;
	padding: 20px;
}
.shipment-card {
	background-color: #fff;
	border-radius: 3px;
	position: relative;
	padding: 15px;
	max-height: 155px;
	transition: 0.3s ease;
	overflow: hidden;
	margin-bottom: 15px;
	box-shadow: 0 0 6px 0px #aaa;
	&.active {
		max-height: unset;
	}
	li {
		font-size: 13px;
		margin-bottom: 5px;
		h5 {
			font-size: 14px;
			margin-bottom: 0;
			font-weight: 600;
		}
	}
}
.shipment-card-toggle {
	font-size: 16px;
	position: absolute;
	bottom: 0;
	left: 0;
	background-color: #fff;
	right: 0;
	text-align: center;
	&.active {
		transform: rotate(180deg);
	}
}
.shipment-board-box {
	width: 100%;
	background-color: #f5f7f9;
	max-width: 400px;
	min-width: 400px;
	.shipment-content-box {
		background-color: #fff;
		padding: 15px;
		border-radius: 5px;
		margin-bottom: 10px;
		box-shadow: 0 0 6px -2px #0e0e0e7a;
	}
	ul {
		li {
			list-style: none;
			font-size: 13px;
			margin-bottom: 5px;
			color: rgb(0 0 0 / 80%);
			h5 {
				font-size: 14px;
				font-weight: 500;
				margin-bottom: 0;
				color: #2f2e2e;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.board-footer-content {
		ul {
			align-items: center;
		}
		li {
			margin-bottom: 0;
		}
	}
}
.shipment-board-body {
	padding: 10px;
	padding-top: 0;
}
.shipment-board-header {
	padding: 15px;
	position: sticky;
	z-index: 1;
	top: 0;
	background-color: #f5f7f9;
	h4 {
		margin-bottom: 0;
		color: $blackColor;
		font-size: 1.6rem;
	}
}
.board-footer-content {
	margin-top: 10px;
	& > li {
		& > a {
			font-size: 1.6rem;
			color: rgb(119 119 119 / 80%);
			display: flex;
			align-items: center;
		}
	}
	li {
		a {
			i {
				margin-right: 10px;
			}
		}
		.board-member-name {
			width: 25px;
			height: 25px;
			background-color: #d0f2cf;
			border-radius: 50%;
			display: inline-block;
			font-size: 11px;
			font-weight: 800;
			text-align: center;
			line-height: 25px;
			margin-right: 10px;
		}
	}
}
.shipment-content-box {
	.dropdown-menu {
		z-index: 1;
	}
}
.left-panel-name {
	max-width: 260px;
	min-width: 260px;
	.shipment-content-box {
		box-shadow: none;
		button {
			font-size: 14px;
			color: #2f2e2e;
			background-color: #ffff;
			border: 1px solid #eee;
			border-radius: 4px;
			span {
				font-size: 12px;
				font-weight: 600;
			}
		}
	}
}
.left-panel-name .shipment-content-box button:hover,
.left-panel-name .shipment-content-box button.active {
	background-color: #e5f2fd;
	border-color: #c2dffd;
}
.zIndex-1 {
	z-index: 1;
}
.shipment-board-wrap {
	margin-top: 15px;
}