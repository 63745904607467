.p-dropdown.p-component{
    padding-top: 1px;
    padding-bottom: 1px;
}
.p-inputtext{
    font-size: 1.4rem;
    &:enabled:focus{
        border-color: #1749b9 ;
    }
}
.p-dropdown-panel {
    .p-dropdown-items{
        padding-top: 0px;
        padding-bottom: 0px;   
            .p-dropdown-item.p-highlight {
                color: #ffffff;
                background: hwb(0deg 30% 70%);
            }     
    }
    
}

.form-control:focus, .p-dropdown:not(.p-disabled).p-focus{
    box-shadow: none;
}
.form-control:focus, .p-dropdown:not(.p-disabled):hover{
    border-color: #1749b9 ;
}

.p-datepicker-title{
    .p-link{
        font-size: 1.4rem;    
    }
    
}


.p-paginator-element.p-link{
    font-size: 14px;
} 
.p-paginator {
    .p-dropdown{
        height: auto; 
    }
    .p-paginator-pages {
        .p-paginator-page{
            min-width: 2.9rem;
            height: 2.9rem;
        }
        :not(.p-highlight):hover{
            background-color: #e9ecef;
            border-color: #dee2e6;
        }
       
        .p-paginator-page.p-highlight{
            background-color: $tabBlueColor;
            border-color: $tabBlueColor;
            color: #ffffff;
        }
    }
    
}

.p-checkbox {
    .p-checkbox-box.p-highlight{
        background: $tabBlueColor;
        border-color: $tabBlueColor !important;      
    }
    &:not(.p-checkbox-disabled){
        .p-checkbox-box.p-highlight:hover{
            background: $tabBlueColor;
            border-color: $tabBlueColor !important;;
        }       
    }
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box{
    &:hover{       
        border-color: #ced4da ;
    }
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus{
    box-shadow: none;
    border-color: #ced4da ;
}

.p-datatable {
    .p-datatable-thead {
        tr{
            th{
                padding: 1rem 1.1rem; 
            }
        }
        
    }
   
}
.p-link:focus{
    box-shadow: none;
}
.p-badge{
    font-size: 10px;
}
