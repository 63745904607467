@media (max-width: 1400px) {
    .departed-dropdown {
      width: auto;
    }
  }
  @media (max-width: 1200px) {
    html {
        font-size: 58%;
    }
    #asidebar {
        left: -100%;
        .sm-logo {
            display: none;
        }
        &:after {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            visibility: hidden;
            transition: 0.3s;
            height: 100%;
            background-color: rgb(0 0 0 / 50%);
            z-index: -2;
        }
        nav {
            overflow: auto;
            height: 100%;
            // padding-bottom: 56px;
            background-color: #fff;
            &:after {
                content: "";
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                opacity: 0;
                visibility: hidden;
                transition: 0.3s;
                height: 100%;
                background-color: rgb(0 0 0 / 50%);
                z-index: -2;
            }
        }
    }
    .template-default {
        &.menu-closed {
            #asidebar {
                left: 0;
                nav {
                    &:after {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
        &.menu-expanded {
            #asidebar {
                width: 280px;
            }
            .sm-logo {
                display: block;
                max-width: 25px;
                img {
                    max-width: 100%;
                }
            }
        }
    }
    .notification-header {
        min-width: auto;
        display: flex !important;
        flex-direction: column;
        .dropdown-body {
            max-height: 100%;
        }
    }
    .profileDropDown,
        .dropdown-slide-right {
        position: fixed !important;
        display: block !important;
        height: 100%;
        top: 0 !important;
        transform: none !important;
        max-width: 280px;
        width: 100%;
        left: auto !important;
        right: -100% !important;
        transition: 0.3s;
    }
    .profile-name-head {
        padding: 4px 0 5px 0;
    }
    .notification-name-head {
        padding: 13px;
    }
    .header-right-links {
        .badge {
            width: 15px;
            height: 15px;
            line-height: 16px;
            left: 3px;
            top: 5px !important;
        }
        & > li {
            & > a {
                font-size: 2.2rem;
            }
        }
    }
    .profileDropDown.show,
        .dropdown-slide-right.show {
        right: 0 !important;
    }
    .profileDropDown {
        li {
            a {
                display: flex;
                justify-content: space-between;
                flex-direction: row-reverse;
                padding: 8px 0;
                padding-left: 10px;
                text-decoration: none;
                align-items: center;
                color: #000;
                position: relative;
                font-size: 1.5rem;
                border-bottom: 1px solid #eee;
                i {
                    font-size: 1.6rem;
                    width: 35px;
                    color: #000;
                    text-align: center;
                    vertical-align: text-bottom;
                }
            }
        }
    }
    .header-body-content,
        .template-default.menu-expanded .header-body-content {
        margin-left: 0;
    }
    .nav-sidebar {
        overflow: auto;
        ul {
            li {
                a {
                    i {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .top-header {
        padding: 13px 9px;
        box-shadow: none;
        border-bottom: 1px solid #dad7d7;
    }
    .departed-dropdown {
        &:after {
            margin-left: 20px;
        }
    }
    .logo {
        background: #fff;
    }
    .night-mode {
        #asidebar {
            nav {
                background: #212529;
            }
        }
        .logo {
            background-color: #30353a;
        }
    }
    .addNewFilter {
        .modal-dialog {
            max-width: 100%;
        }
    }
    .login-right-box {
        margin: auto;
        max-width: 570px;
    }
    .login-page-bg {
        overflow: auto;
    }
    .main-footer {
        padding: 10px 0;
    }
    .offcanvas.xxl,
        .offcanvas.xl,
        .offcanvas.lg,
        .offcanvas.sm {
        max-width: 80% !important;
    }
    .logoutDropdown:after{
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 0;
    }
    .logoutDropdown.show:after{
        opacity: 1;
        visibility: visible;
    }
  }

  @media (max-width: 991px) {
    .chat-right-page-body,
        .support-chat-new.chat-system-wrap .chat-right-page-body {
        padding: 15px;
    }
  }

  @media (max-width: 767px) {
    .login-box {
        height: auto;
    }
    .position-relative {
        &.order-1 {
            &.order-md-2 {
                width: 100%;
                max-width: 230px;
            }
        }
    }
    .departed-dropdown {
        min-width: 200px;
    }
    .departed-dropdown-wrap {
        width: 50%;
        margin-left: 20px;
    }
    .support-chat-new {
        &.chat-system-wrap {
            .chat-left-asidebar {
                min-width: unset;
                max-width: 100%;
            }
        }
        .chat-right-page-footer {
            min-height: 50px;
        }
    }
    .user-chat-search {
        justify-content: end;
    }
    .user-search-input-div {
        width: 100%;
        overflow: hidden;
        transition: 0.3s;
        margin-right: 10px;
        position: fixed;
        left: 0;
        margin-top: 35px;
        z-index: 3;
        padding: 8px;
        display: none;
        &.active {
            display: block;
        }
        .form-control {
            padding: 10px 5px;
        }
    }
    .chat-system-wrap {
        .chat-left-asidebar {
            min-width: 100%;
            overflow: auto !important;
        }
        .chat-right-page {
            position: fixed;
            top: 66px;
            z-index: 6;
            max-height: calc(100% - 66px)!important;
            left: -100%;
            width: 100%;
            transition: 0.3s ease;
            -webkit-transition: 0.3s ease;
            -moz-transition: 0.3s ease;
            -ms-transition: 0.3s ease;
            -o-transition: 0.3s ease;
            &.active {
                left: 0;
            }
        }
    }
    .chat-right-page-body {
        &.overflow-auto {
            overflow: auto !important;
        }
    }
    
  }

  @media (max-width: 576px) {
    .progress-departed-arrived {
        min-width: unset;
        & > li {
            padding: 0;
            a {
                max-width: 100%;
                width: 100%;
                text-align-last: left;
            }
            .close {
                right: 15px;
            }
        }
    }
    .shipment-board-box{
        min-width: 100%;
    }
    .custom-dessign-dropdown {
        .filterBox {
            .title {
                padding-left: 15px;
            }
        }
    }
    .dropdown-large,
        .dropdown-x-large {
        min-width: 300px;
    }
    .filter-form-wrap {
        padding: 0 15px;
    }
    .select-filter-wrap {
        overflow: auto;
        & > .row {
            min-width: 690px;
        }
    }
    .selected-column,
        .dropdown-with-search ul {
        max-height: unset;
    }
    .custom-tabs {
        .nav {
            gap: 5px;
            li {
                &.nav-item {
                    flex: 1;
                    text-align: center;
                }
            }
        }
    }
    .notification-header {
        .dropdown-body {
            .notify-img {
                display: none;
            }
        }
    }
    .main-search-box {
        width: 100%;
        margin-right: 20px;
    }
    .left-panel-name {
        max-width: 260px;
        min-width: 260px;
    }
    .modal-open-right .close-btn,
        .dropdownRight .dropdown-close-btn {
        right: 0;
        left: auto;
    }
    .offcanvas.xxl,
    .offcanvas.xl,
    .offcanvas.lg,
    .offcanvas.sm {
        max-width: 100% !important;
    }
    .offcanvas {
        .close-btn {
            position: static;
            width: auto;
            height: auto;
        }
    }
    
  }
  
  @media (min-width: 1200px) {
    .blurDropdownBg.profileDropDownWrap::before,
    .blurNoneXl::before {
	    display: none;
    }
    .profileDropDown {
        a {
            i {
                margin-right: 5px;
            }
        }
    }


    @media (-webkit-min-device-pixel-ratio: 1.0416666666666667), (-o-min-device-pixel-ratio: 25/24), (min-resolution: 100dpi) {
      html {
        zoom: 0.9;
        -moz-transform: scale(0.9);
      }
    }
    @media (-webkit-min-device-pixel-ratio: 1.3125), (-o-min-device-pixel-ratio: 21/16), (min-resolution: 126dpi) {
      html {
        zoom: 0.8;
        -moz-transform: scale(0.8);
      }
    }
    @-moz-document url-prefix() {
      body {
        position: static;
        height: 100vh;
      }
      @media (-webkit-min-device-pixel-ratio: 1.0416666666666667), (-o-min-device-pixel-ratio: 25/24), (min-resolution: 100dpi) {
        html {
          zoom: 0.9;
          -moz-transform: scale(1);
        }
      }
  
      @media (-webkit-min-device-pixel-ratio: 1.3125), (-o-min-device-pixel-ratio: 21/16), (min-resolution: 126dpi) {
        html {
          zoom: 0.8;
          -moz-transform: scale(1);
        }
      }
    }
  }
  