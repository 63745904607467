
.chatBoxWrap {
	.chatBox {
		margin-bottom: 10px;
	}
	.chatboxHeader {
		display: flex;
		justify-content: space-between;
		padding: 10px 20px;
		align-items: center;
	}
	.chatBoxBody {
		font-size: 1.6rem;
		padding: 25px;
		border: 1px solid #e2e4e6;
	}
	.lightBlue {
		.chatboxHeader {
			background-color: hwb(209deg 71% 11% / 51%);
		}
		.chatBoxBody {
			background-color: $whiteColor;
		}
	}
	.lightGreen {
		.chatboxHeader {
			background-color: #e2e4e6;
		}
		.chatBoxBody {
			background-color: $whiteColor;
		}
	}
}
.chat-system-wrap {
	.chat-left-asidebar {
		min-width: 390px;
		max-width: 390px;
		width: 100%;
		border-right: 1px solid #e2e4e6;
		border-right: 1px solid #e2e4e6;
	}
	.chat-right-page {
		flex-grow: 1;
	}
}
.user--profile {
	margin-bottom: 0;
	min-width: 40px !important;
	height: 40px;
	overflow: hidden;
	cursor: pointer;
	border-radius: 50% !important;
	min-width: 40px;
	img {
		width: 100%;
		height: 100%;
	}
}
.chatbox-dropdown-left-slide {
	position: static;
	.dropdown-menu {
		width: 100%;
		height: 100%;
		transform: none !important;
		inset: unset !important;
		margin: 0 !important;
		min-width: 100%;
		right: auto !important;
		left: -100% !important;
		top: 0 !important;
		padding: 0;
		z-index: 1;
		transition: 0.3s ease;
		display: block !important;
		&.show {
			left: 0 !important;
		}
	}
}
.chat-left-header {
	.btn-group {
		position: static;
	}
}
.replyBtnBlue {
	background-color: #d9edf7 !important;
	color: $blackColor !important;
	&.accordion-button {
		&::after {
			margin-left: 50px;
		}
	}
}


.chat-user-content {
	position: relative;
	h4 {
		color: #393939;
		text-transform: capitalize;
		& + span {
			white-space: nowrap;
		}
	}
	a {
		color: $blackColor;
	}
	.msg-counter {
		position: absolute;
		width: 25px;
		height: 25px;
		display: flex;
		font-size: 12px;
		justify-content: center;
		align-items: center;
		font-weight: 700;
		border-radius: 50%;
		background: $blueColor;
		right: 0px;
		bottom: -7px;
		color: $whiteColor;
		line-height: 25px;
	}
}
.chat-users-list {
	.chat-user {
		padding-left: 25px;
		padding-right: 15px;
		padding-top: 14px;
		padding-bottom: 14px;
		border-bottom: 1px solid #e2e4e6;
	}
}
.chat-users-list .chat-user:hover,
.chat-users-list .chat-user.active {
	background-color: #e2e4e6;
}
.chat-left-header .user--profile-wrap,
.chat-right-page-header {
	min-height: 65px;
	background-color: #f0f2f5;
	border-bottom: 1px solid #e2e4e6;
	max-height: 65px;
	height: 65px;
}
.chat-right-page-footer {
	background-color: #f0f2f5;
	min-height: 70px;
	display: flex;
	align-items: center;
	border-top: 1px solid #dee2e6;
}
.user-chat-search {
	display: flex;
}
.user-search-input-div {
	width: 0;
	overflow: hidden;
	transition: 0.3s;
	margin-right: 10px;
	.form-control {
		box-shadow: none;
		padding-left: 40px;
		width: 100%;
	}
	&.active {
		width: 100%;
	}
}
.chatStatusBtn {
	font-size: 2.2rem !important;
	padding: 0;
	border: none;
	&::after{
		content: none;
	}
	&.active {
		position: relative;
		&::after {
			content: "";
			width: 8px;
			height: 8px;
			background-color: $blueColor;
			position: absolute;
			top: 3px;
			right: 0px;
			border-radius: 50%;
		}
	}
}
.chat-right-page-body {
	background-color: #f7f8fa;
	padding: 40px 60px;
}
.user-chat-box {
	display: inline-block;
	padding: 10px;
	border-radius: 5px;
	margin-bottom: 15px;
}
.chat-box-left {
	.user-chat-box {
		background-color: $whiteColor;
		position: relative;
		&::before {
			content: "";
			position: absolute;
			border-style: solid;
			border-width: 10px;
			border-color: transparent $whiteColor transparent transparent;
			left: -16px;
		}
	}
}
.chat-box-right {
	text-align: right;
	.user-chat-box {
		background-color: #e3e6f8;
		max-width: 80%;
		margin-left: auto;
		text-align: left;
		padding-left: 20px;
	}
}
.typing-chat-input {
	height: 55px;
	margin-left: 10px;
	font-size: 16px;
	color: #e2e4e6;
	padding-left: 20px;
	padding-right: 20px;
	font-weight: 600 !important;
	box-shadow: none;
	outline: none;
}
.form-check {
	.form-check-input {
		font-size: 2rem;
	}
}
.support-chat-new {
	&.chat-system-wrap {
		.chat-right-page-body {
			padding: 20px;
		}
	}
}
