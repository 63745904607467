.loader-wrap{
    position: fixed;
    background: #000000ab;
    width: 100%;
    height: 100%;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    bottom: 0;
  }
  
  .loader {
    width: 48px;
    height: 48px;
    border: 5px dotted #009b01;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    -webkit-animation: rotation 2s linear infinite;
            animation: rotation 2s linear infinite;
  }
  
  .loader-1 {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: #009b01;
    border-radius: 50%;
    display: inline-block;
    -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
  }
  
  .loader-2 {
    width: 48px;
    height: 48px;
    border: 3px solid #FFF;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
  }
  .loader-2:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-bottom-color: #009b01;
  }
  
  .loader-3 {
    width: 48px;
    height: 48px;
    border: 3px solid #FFF;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
  }
  .loader-3:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-bottom-color: #009b01;
  }
  
  .loader-4 {
    width: 48px;
    height: 48px;
    border: 3px solid #FFF;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
  }
  .loader-4:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid;
    border-color: #009b01 transparent;
  }
  
  .loader-5 {
    width: 48px;
    height: 48px;
    border: 3px solid #FFF;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
  }
  .loader-5:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 3px solid;
    border-color: #009b01 transparent;
  }
  
  .loader-6 {
    width: 48px;
    height: 48px;
    border: 2px solid #FFF;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
  }
  .loader-6:after, .loader-6:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: #009b01;
    width: 6px;
    height: 6px;
    transform: translate(150%, 150%);
    border-radius: 50%;
  }
  .loader-6:before {
    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
    transform: translate(-150%, -150%);
  }
  
  .loader-7 {
    width: 48px;
    height: 48px;
    border: 2px solid #FFF;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
  }
  .loader-7:after, .loader-7:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: #009b01;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
  .loader-7:before {
    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
  }
  
  .loader-8 {
    width: 48px;
    height: 48px;
    border: 3px solid #FFF;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
  }
  .loader-8:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: #009b01;
    width: 16px;
    height: 16px;
    transform: translate(-50%, 50%);
    border-radius: 50%;
  }
  
  .loader-9 {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 2px solid #009b01;
    -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
  }
  .loader-9:after {
    content: "";
    position: absolute;
    left: 4px;
    top: 4px;
    border: 2px solid #FFF;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  
  .loader-10 {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #FFF #FFF transparent;
    -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
  }
  .loader-10:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent #009b01 #009b01;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    -webkit-animation: rotationBack 0.5s linear infinite;
            animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }
  
  .loader-11 {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #FFF #FFF transparent transparent;
    -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
  }
  .loader-11:after, .loader-11:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent #009b01 #009b01;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -webkit-animation: rotationBack 0.5s linear infinite;
            animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }
  .loader-11:before {
    width: 32px;
    height: 32px;
    border-color: #FFF #FFF transparent transparent;
    -webkit-animation: rotation 1.5s linear infinite;
            animation: rotation 1.5s linear infinite;
  }
  
  .loader-12 {
    width: 48px;
    height: 48px;
    border: 3px dotted #FFF;
    border-style: solid solid dotted dotted;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    -webkit-animation: rotation 2s linear infinite;
            animation: rotation 2s linear infinite;
  }
  .loader-12:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px dotted #009b01;
    border-style: solid solid dotted;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    -webkit-animation: rotationBack 1s linear infinite;
            animation: rotationBack 1s linear infinite;
    transform-origin: center center;
  }
  
  .loader-13 {
    width: 48px;
    height: 48px;
    border: 2px solid #FFF;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
  }
  .loader-13:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    background: #009b01;
    width: 3px;
    height: 24px;
    transform: translateX(-50%);
  }
  
  .loader-14 {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
  }
  .loader-14::after, .loader-14::before {
    content: "";
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid #FFF;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-animation: animloader14 2s linear infinite;
            animation: animloader14 2s linear infinite;
  }
  .loader-14::after {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  
  .loader-15 {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    background: linear-gradient(0deg, rgba(255, 61, 0, 0.2) 33%, #009b01 100%);
    -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
  }
  .loader-15:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #263238;
  }
  
  .loader-16 {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid #FFF;
    border-right: 3px solid transparent;
    -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
  }
  
  .loader-17 {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 4px solid #FFF;
    border-right: 4px solid transparent;
    -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
  }
  .loader-17:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-bottom: 4px solid #009b01;
    border-left: 4px solid transparent;
  }
  
  .loader-18 {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 4px solid #FFF;
    border-right: 4px solid transparent;
    -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
  }
  .loader-18:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-left: 4px solid #009b01;
    border-bottom: 4px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
  }
  
  .loader-19 {
    width: 48px;
    height: 48px;
    border: 5px solid;
    border-color: #009b01 transparent;
    border-radius: 50%;
    display: inline-block;
    -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
  }
  
  .loader-20 {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 10px solid;
    border-color: rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.5);
    -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
  }
  
  /* keyFrames */
  @-webkit-keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
  
  @-webkit-keyframes animloader14 {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  @keyframes animloader14 {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }