select {
	outline: none;
	&.no-select-angle {
		background-image: none;
	}
}

.form-control {
	padding: 7px 5px;
	height: inherit;
	font-size: 1.4rem;
	box-shadow: none !important;
	&.select {
		background-image: url(../img/select-angle.png);
		background-repeat: no-repeat;
		background-position: right 8px center;
	}
}
.text-light-grey {
	color: $lightGrayColor !important;
}
.text-blue {
	color: $blueColor !important;
}
.border-blue {
	border-color: $blueColor !important;
}
.bg-blue {
	background-color: $blueColor !important;
}
.text-green {
	color: $greenColor !important;
}
.border-green {
	border-color: $greenColor !important;
}
.bg-green {
	background-color: $greenColor !important;
}
.text-red {
	color: $redColor !important;
}
.border-red {
	border-color: $redColor !important;
}
.bg-red {
	background-color: $redColor !important;
}
.mw100 {
	width: 100%;
	max-width: 100px;
}
.mw150 {
	width: 100%;
	max-width: 150px;
}
.mw200 {
	width: 100%;
	max-width: 200px;
}
.btn-blue {
	background-color: $blueColor !important;
}
.btn-gradient {
	background: rgb(245, 247, 249);
	background: linear-gradient(0deg, rgba(245, 247, 249, 1) 0%, rgba(255, 255, 255, 1) 100%);
	border-color: #cfd7df !important;
}
.btn {
	font-size: 1.4rem;
	white-space: nowrap;
	&.btn-gradient {
		&:hover {
			background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(245, 247, 249, 1) 100%);
			border: 1px solid #cfd7df;
		}
	}
	&:first-child {
		&:active {
			border-color: transparent;
		}
	}
}
.text-red-2 {
	color: #db0f79;
}
.bg-red-2 {
	background-color: #db0f79;
}


@for $i from 1 through 9 {
	.fw#{$i}00 {
		font-weight: #{$i}00;
	}
}

@for $i from 0 through 40 {
	._mt-#{$i*5} {
		margin-top: #{$i*5}px;
	}
}
@for $i from 0 through 40 {
	._mb-#{$i*5} {
		margin-bottom: #{$i*5}px;
	}
}
@for $i from 0 through 40 {
	._pt-#{$i*5} {
		padding-top: #{$i*5}px;
	}
}
@for $i from 0 through 40 {
	._pb-#{$i*5} {
		padding-bottom: #{$i*5}px;
	}
}

@for $i from 6 through 20 {
	.fz#{( $i * 2)} {
	  font-size: $i * 0.2rem !important
	}
}





ul {
	padding: 0px;
	margin: 0px;
	list-style-type: none;
}
a {
	text-decoration: none;
}
p,
input,
button,
select,
textarea,
span,
a,
div,
.form-select-sm,
table,
.form-group,
.dataTables_length,
.dataTables_filter,
.dataTables_info,
.pagination {
	font-size: 1.4rem;
}
.dropdown-toggle {
	font-size: 1.4rem !important;
}



.outline-0 {
	outline: none;
}

.btn-group {
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 1.4rem;
	* {
		border-radius: 4px !important;
	}
}
.modal-backdrop {
	width: 100%;
	height: 100%;
}

.dropdown-menu {
	min-width: 160px;
	min-width: 160px;
	font-size: 1.4rem;
}
