
.top-header {
	padding: 10.5px 15px;
	box-shadow: 5px 1px 4px 0px #ccc;
	z-index: 7;
}

.profile-icon {
	display: inline-block;
	width: 30px;
	height: 30px;
	background: $blueColor;
	text-align: center;
	line-height: 30px;
	font-size: 1.3rem;
	text-transform: uppercase;
	color: #fff;
}
.logo {
	white-space: nowrap;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 10px !important;
	min-height: 56px;
	overflow: hidden;
	position: relative;
}
.hamburger {
	width: 24px;
	height: 24px;
	border: 1px solid #ececec;
	border-radius: 50%;
	background: #fff;
	font-size: 1.2rem;
	color: #757373;
	position: absolute;
	right: -11px;
	z-index: 2;
	bottom: -11px;
}

.coles-supermarket {
	li {
		a {
			white-space: unset;
		}
	}
	.dropdown-menu {
		max-width: 300px;
	}
}

.notification-header {
	.dropdown-body {
		max-height: 350px;
		overflow: auto;
		.notify-img {
			min-width: 40px;
			height: 40px;
			border-radius: 50%;
			overflow: hidden;
			background: #eee;
			font-size: 1.6rem;
			font-weight: 600;
			display: flex;
			justify-content: center;
			align-items: center;
			color: $blueColor;
		}
		li {
			padding: 10px;
			border-bottom: 1px solid #eee;
		}
	}
}

.header-right-links {
	& > li {
		a {
			font-size: 2.4rem;
			color: $blackColor;
			margin-left: 10px;
			position: relative;
		}
		&:hover {
			& > a {
				color: #ce2027;
			}
		}
	}
	.badge {
		background-color: #ef4047;
		font-size: 10px;
		width: 20px;
		height: 20px;
		padding: 0;
		font-weight: 600;
		line-height: 20px;
		border-radius: 50%;
		left: 3px;
		top: 6px !important;
	}
}

.header-right-links {
	.profileDropDown {
		a {
			font-size: 1.4rem;
			margin-left: 0;
		}
	}
}

.notification-header {
	min-width: 390px;
	padding: 0;
}

.notification-header {
	min-width: 390px;
	padding: 0;
	.dropdown-body {
		max-height: 350px;
		overflow: auto;
		.notify-img {
			min-width: 40px;
			height: 40px;
			border-radius: 50%;
			overflow: hidden;
			background: #eee;
			font-size: 1.6rem;
			font-weight: 600;
			display: flex;
			justify-content: center;
			align-items: center;
			color: $blueColor;
		}
		li {
			padding: 10px;
			border-bottom: 1px solid #eee;
		}
	}
}